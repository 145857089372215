import React from 'react';
import Radio from './Radio';
import Player from './Player';

import './App.css';

function App() {
  return (
    
      <Radio></Radio>
    
  )
}

export default App;
