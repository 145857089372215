import React from 'react';
import Player from './Player';
import {Col, Container}from "react-bootstrap";
import logo from './assest/LOGOBLANCO-01.png'
import './Radio.css';

const Radio=()=>{
return (
    <>
   
   
    <Player></Player>
    
    </>
)
  

}
export default Radio;